import React from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import { useIntl, Link, navigate } from "gatsby-plugin-intl";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { connect } from "react-redux";
import Tools from "../classes/tools";

const Faqs = ({ s }) => {
	const intl = useIntl();

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="faqs">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title={Tools.convertString(s.faqs?.title)} />
					<main className="page">
						<article className="bg-img">
							<h1 className="white-text prequel-font">{Tools.convertString(s.faqs?.title)}</h1>
							<picture>
								<source srcset="/img/bg/faqs-desktop.jpg" media="(min-width: 768px)" />
								<img srcset="/img/bg/faqs.jpg" alt="" />
							</picture>
						</article>

						<article className="questions box">
							<ul className="breadcrumbs white-bg">
								<li className="link">
									<Link to="/" className="ltblue-text-02">
										Home
									</Link>
								</li>
								<li className="active">
									<p className="lightgray-text-07">Faqs</p>
								</li>
							</ul>
							<ul className="box list">
								<li className="black-text-01">
									<h5 className="red-text prequel-font">¿POR QUE ALQUILAR MI TABLA?</h5>
									<p>
										Siempre se tiene una tabla que se usa menos o que está ya desfasada para nuestro nivel.
										Ahora puedes sacarle provecho y así sacarte un dinero extra para comprar una nueva tabla o
										incluso ¡hacer un surf trip!
									</p>
								</li>
								<li className="black-text-01">
									<h5 className="red-text prequel-font">¿Y QUE VENTAJAS HAY EN ALQUILAR EN SYB?</h5>
									<p>
										En Share Your Board le devolvemos el protagonismo al surfista. Te conectamos con personas
										como tú, que quieren darse un buen baño, conocer los mejores spots y además, ayudar a otro
										compañero surfero.
									</p>
								</li>
								<li className="black-text-01">
									<h5 className="red-text prequel-font">¿QUE TIPO DE GARANTIAS TENGO? </h5>
									<p>
										Para tu tranquilidad, todos los usuarios estarán valorados dentro de la comunidad e
										identificados con DNI, así impedimos cualquier acto fraudulento. Además, sólo permitimos
										el alquiler por horas, de manera que evitemos que la persona que alquile tu tabla tenga
										que transportarla fuera del agua. Visita nuestra sección{" "}
										<span>
											<Link to="/porquecompartir" className="red-text">
												Por qué compartir
											</Link>
										</span>{" "}
										para saber más o envíanos un email con tus dudas a{" "}
										<a class="ltblue-text" href="mailto:hello@shareyourboard.app">
											hello@shareyourboard.app
										</a>
										.
									</p>
								</li>
								<li className="black-text-01">
									<h5 className="red-text prequel-font">
										¿COMO ME ASEGURO DE QUE NO LE PASE NADA A MI TABLA?
									</h5>
									<p>
										Desde Share Your Board no podemos asegurar tu tabla pero te recomendamos algunas acciones
										para minimizar al máximo los riesgos como quedar en la playa con la persona interesada
										para que así no haya posibilidad de toques en el camino a la playa. También, siempre vas a
										tener la libertad de aceptar o no una solicitud de alquiler, si el usuario no te da la
										confianza suficiente. Y ¿por qué́ no entrar en el agua y surfear juntos?. ¡A lo mejor
										puedes aprender alguna que otra cosa!
									</p>
								</li>
								<li className="black-text-01">
									<h5 className="red-text prequel-font">
										¿POR QUE ALQUILAR CON SYB SI PUEDO IR A UNA TIENDA?
									</h5>
									<p>
										Creemos firmemente que la sostenibilidad, el cuidado por nuestro entorno y la creación de
										un modelo colaborativo son esenciales para mejorar el mundo en el que vivimos. Además, es
										una opción perfecta para cuando la tienda de alquiler no tiene la tabla que buscas o no
										puedes hacer una reserva con días de antelación. Sin embargo, te recomendamos que si eres
										principiante y quieres iniciarte en el mundo del surf, contrates un curso en una escuela.
									</p>
								</li>
							</ul>
						</article>
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(Faqs);
